function getISOWeekNumber(date) {
	const tempDate = new Date(date);
	tempDate.setDate(tempDate.getDate() + (4 - (tempDate.getDay() || 7)));
	const yearStart = new Date(tempDate.getFullYear(), 0, 1);
	const weekNumber = Math.ceil(((tempDate - yearStart) / 86400000 + 1) / 7);
	return weekNumber;
}

function getFirstWeekNumberOfMonth() {
	const today = new Date();
	const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
	const firstWeekNumber = getISOWeekNumber(firstDayOfMonth);
	return firstWeekNumber;
}

export const generateData = (period, input) => {
	if (!input.length) {
		return [];
	}

	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const firstWeek = getFirstWeekNumberOfMonth();

	if (period === "year") {
		const data = [];
		for (let i = 0; i < 12; i++) {
			const month = input.filter((d) => Number(d.period) === i + 1);
			data.push({
				date: months[i],
				Storage: month.length
					? (month[0].total_storage / Math.pow(10, 9)).toFixed(2)
					: 0,
				Bandwidth: month.length
					? (month[0].total_bandwidth / Math.pow(10, 9)).toFixed(2)
					: 0,
			});
		}
		return data;
	} else if (period === "month") {
		const data = [];
		for (let i = firstWeek; i < firstWeek + 4; i++) {
			const week = input.filter((d) => Number(d.period) === i);
			data.push({
				date: i,
				Storage: week.length
					? (week[0].total_storage / Math.pow(10, 9)).toFixed(2)
					: 0,
				Bandwidth: week.length
					? (week[0].total_bandwidth / Math.pow(10, 9)).toFixed(2)
					: 0,
			});
		}
		return data;
	} else if (period === "day") {
		return [
			{
				date: input[0].period || 1,
				Storage:
					(input[0].total_storage / Math.pow(10, 9)).toFixed(2) || 0,
				Bandwidth:
					(input[0].total_bandwidth / Math.pow(10, 9)).toFixed(2) ||
					0,
			},
		];
	}
	return [];
};
