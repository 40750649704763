export function formatBytes(bytes) {
	if (bytes === 0) return "0 Bytes";

	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const isNegative = bytes < 0;
	const absBytes = Math.abs(bytes);
	const i = Math.floor(Math.log(absBytes) / Math.log(1000));
	const formattedValue = parseFloat(
		(absBytes / Math.pow(1000, i)).toFixed(2)
	);

	return `${isNegative ? "-" : ""}${formattedValue} ${sizes[i]}`;
}
