import React from "react";

const Progress = ({ width, background, height, icon = false }) => {
	return (
		<div
			className={`w-full h-4  bg-purpleSecondary shadow-[inset_0_-2px_15px_#4a2576] ${
				icon && "!cursor-pointer *:!cursor-pointer"
			}`}
			style={{ height: height != null && `${height}px` }}
		>
			<div
				style={{ width: `${width}%`, background }}
				className="h-full relative"
			>
				<div className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2">
					{icon && (
						<img
							src="/images/icon.webp"
							className="wolvy-progress cursor-pointer"
							style={{ height }}
							alt="Wolvy Logo"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Progress;
