import React, { useEffect, useState } from "react";
import { getUser } from "../../utils/getUser";
import axios from "axios";
import { getAPIKey } from "../../utils/getAPIKey";

const PlayerTheme = ({ player_theme }) => {
	const [playerTheme, setPlayerTheme] = useState(player_theme);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const handleSubmit = () => {
		setLoading(true);
		setDone(false);
		setError(false);
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("theme_id", playerTheme);
		axios
			.post("/update_theme.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Player Theme</p>
			<div className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active">
				<form className="flex-1 flex flex-col justify-between gap-5 *:font-bold">
					<div className="flex justify-between items-stretch gap-5">
						<div
							className={`cursor-pointer gap-5 mb-5 border-2 rounded-lg border-[transparent] ${
								playerTheme === "1"
									? "bg-yellow button-lg-no-active"
									: ""
							}`}
							onClick={() => setPlayerTheme("1")}
						>
							<img
								src="/images/player-theme-2.webp"
								className="md:w-full md:h-44 object-contain object-top"
								alt="Player Theme 1"
							/>
							<p
								className={`text-center my-2 text-white ${
									playerTheme === "1"
										? "!text-purpleSecondary"
										: ""
								}`}
							>
								8-Bit Player
							</p>
						</div>
						<div
							className={`cursor-pointer gap-5 mb-5 border-2 rounded-lg border-[transparent] ${
								playerTheme === "2"
									? "bg-yellow button-lg-no-active"
									: ""
							}`}
							onClick={() => setPlayerTheme("2")}
						>
							<img
								src="/images/player-theme-1.webp"
								className="md:w-full md:h-44 object-contain object-top"
								alt="Player Theme 2"
							/>
							<p
								className={`text-center my-2 text-white ${
									playerTheme === "2"
										? "!text-purpleSecondary"
										: ""
								}`}
							>
								Modren Player
							</p>
						</div>
						{/* <div className="cursor-pointer">
							<img
								src="/images/player-theme-1.webp"
								className={`
													border-2 rounded-lg border-[transparent]
													 ${playerTheme === 3 ? "border-white" : ""}`}
								alt="Player Theme 1"
								onClick={() => setPlayerTheme("3")}
							/>
						</div> */}
					</div>
					<input
						type="submit"
						className="button-lg p-3"
						value={loading ? "LOADING..." : "UPDATE"}
						disabled={loading}
						onClick={handleSubmit}
					/>
				</form>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">Player Theme has been updated!</p>
				)}
			</div>
		</div>
	);
};

export default PlayerTheme;
