import React, { useState, createContext, useEffect } from "react";

export const Uploading = createContext();

export const UploadingProvider = ({ children }) => {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [filesUploading, setFilesUploading] = useState([]);
	const [currentFile, setCurrentFile] = useState(null);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (currentFile !== null) {
				const message =
					"You have an ongoing upload. Are you sure you want to leave?";
				event.preventDefault();
				event.returnValue = message; // For most browsers
				return message; // For some older browsers
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [currentFile]);

	return (
		<Uploading.Provider
			value={{
				uploadProgress,
				setUploadProgress,
				filesUploading,
				setFilesUploading,
				currentFile,
				setCurrentFile,
			}}
		>
			{children}
		</Uploading.Provider>
	);
};
