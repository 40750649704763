import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MenuBackground } from "../context/MenuContext";

const Menu = () => {
	const [scroll, setScroll] = useState(false);
	const { menuBackground } = useContext(MenuBackground);
	const [menu, setMenu] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			const scrollTop =
				window.scrollY || document.documentElement.scrollTop;
			if (scrollTop >= 100) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		if (menuBackground === 0) {
			handleScroll();
			window.addEventListener("scroll", handleScroll);
		}
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [menuBackground]);

	return (
		<div
			className={`md:p-3 p-0 flex justify-between md:flex-row flex-col md:gap-0 gap-3 fixed top-0 w-full z-50 ${
				scroll && "bg-gray"
			}`}
			style={{ background: menuBackground !== 0 && menuBackground }}
		>
			<div className="p-3 md:p-0 flex justify-between items-center">
				<img src="/images/logo.webp" className="w-52" alt="Wolvy" />
				<div
					onClick={() => setMenu(!menu)}
					className="cursor-pointer md:hidden flex"
				>
					<FontAwesomeIcon icon={faBars} className="text-xl" />
				</div>
			</div>
			<ul
				className={`flex md:!h-auto items-center overflow-hidden [&_li]:w-full [&_li]:md:w-auto [&_li]:text-center [&_li]:md:border-0 [&_li]:border-b-[1px] [&_li]:md:p-0 [&_li]:pb-2 [&_li]:border-purplePrimary [&_a]:px-3 gap-3 font-bold md:flex-row flex-col md:bg-transparent bg-purpleSecondary md:p-0 ${
					menu ? "p-5 h-auto" : "p-0 h-0"
				}`}
			>
				<li>
					<Link onClick={() => menu && setMenu(false)} to="/">
						Home
					</Link>
				</li>
				<li>
					<Link onClick={() => menu && setMenu(false)} to="/about">
						About
					</Link>
				</li>
				<li>
					<Link
						onClick={() => menu && setMenu(false)}
						to="/security-comparison"
					>
						Security Comparison
					</Link>
				</li>
				<li>
					<Link onClick={() => menu && setMenu(false)} to="/pricing">
						Pricing
					</Link>
				</li>
				<li>
					<Link
						onClick={() => menu && setMenu(false)}
						to="/contact-us"
					>
						Contact
					</Link>
				</li>
				<li className="md:me-5 text-2xl !border-0">
					<Link
						onClick={() => menu && setMenu(false)}
						aria-label="Login"
						to="/login"
					>
						<FontAwesomeIcon
							className="*:text-purplePrimary"
							icon={faUser}
						/>
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default Menu;
