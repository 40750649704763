import axios from "axios";
import { getUser } from "./getUser";
import { getAPIKey } from "./getAPIKey";

export const moveToFolder = async (data, folderID) => {
	try {
		let response;
		const user = getUser();
		const apiKey = getAPIKey();
		for (const video of data) {
			const formData = new FormData();
			formData.append("user_id", user);
			formData.append("api_key", apiKey);
			formData.append("video_id", video.id);
			formData.append("folder_id", folderID);
			response = await axios.post("/move_video.php", formData);
		}
	} catch (error) {}
};
