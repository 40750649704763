import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Pagination = ({ pages, current, update }) => {
	if (pages <= 1) return null;

	// Generate page numbers with ellipses for large page sets
	const generatePageNumbers = () => {
		const totalPages = pages;
		let pageNumbers = [];

		if (totalPages <= 5) {
			// If 5 or less pages, show them all
			for (let i = 1; i <= totalPages; i++) {
				pageNumbers.push(i);
			}
		} else {
			if (current <= 3) {
				// Near the beginning; show first 4 pages, ellipsis, and last page
				pageNumbers = [1, 2, 3, 4, "...", totalPages];
			} else if (current >= totalPages - 2) {
				// Near the end; show first page, ellipsis, and last 4 pages
				pageNumbers = [
					1,
					"...",
					totalPages - 3,
					totalPages - 2,
					totalPages - 1,
					totalPages,
				];
			} else {
				// Somewhere in the middle; show first page, ellipsis, current -1, current, current +1, ellipsis, last page
				pageNumbers = [
					1,
					"...",
					current - 1,
					current,
					current + 1,
					"...",
					totalPages,
				];
			}
		}

		return pageNumbers;
	};

	const pageNumbers = generatePageNumbers();

	return (
		<div className="flex gap-5 items-center justify-center flex-wrap">
			{/* Previous Button */}
			<div
				className={`h-7 w-7 button-lg flex justify-center items-center mt-3 ${
					current === 1 && "!bg-purplePrimary/10 !cursor-not-allowed"
				}`}
				onClick={() => current !== 1 && update(current - 1)}
			>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className={`${current === 1 && "*:!cursor-not-allowed"}`}
				/>
			</div>

			{/* Page Numbers */}
			{pageNumbers.map((page, index) =>
				page === "..." ? (
					<span
						key={index}
						className="h-7 flex justify-center items-center mt-3"
					>
						...
					</span>
				) : (
					<div
						key={index}
						className={`h-7 w-7 button-lg flex justify-center items-center mt-3 ${
							current === page && "!bg-purpleSecondary"
						}`}
						onClick={() => current !== page && update(page)}
					>
						{page}
					</div>
				)
			)}

			{/* Next Button */}
			<div
				className={`h-7 w-7 button-lg flex justify-center items-center mt-3 ${
					current === pages &&
					"!bg-purplePrimary/10 !cursor-not-allowed"
				}`}
				onClick={() => current !== pages && update(current + 1)}
			>
				<FontAwesomeIcon
					icon={faChevronRight}
					className={`${
						current === pages && "*:!cursor-not-allowed"
					}`}
				/>
			</div>
		</div>
	);
};

export default Pagination;
