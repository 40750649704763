import React from "react";
import FAQ from "../components/FAQ";
import NeedHelp from "../components/NeedHelp";

const SecurityComparison = () => {
	const standard = [
		"Download Protection: Prevent unauthorized downloading of your videos.",
		"ClearKey AES-128: Basic encryption to safeguard your content.",
		"Browser Support: Seamless compatibility with major web browsers.",
		"Integrated Player: Built-in video player for effortless streaming.",
		"Custom Player Support: Personalize your video experience with custom player options.",
	];
	const advanced = [
		"Download Protection: Prevent unauthorized downloading of your videos.",
		"Secure Key Management: Keep your encryption keys safe and secure.",
		"Browser Support: Seamless compatibility with major web browsers.",
		"Integrated Player: Built-in video player for effortless streaming.",
		"Widevine & FairPlay Support: Comprehensive DRM protection for all devices.",
		"Screenshot/Screen Record Protection: Industry-leading features to block screen grabs.",
		"Custom Player Support: Personalize your video experience with custom player options.",
	];
	return (
		<div className="bg-[rgb(22,22,22)] h-full">
			<div className="md:p-10 p-5">
				<p className="font-bold text-4xl mb-3">
					Compare Security Plans
				</p>
				<p>
					Explore the features and benefits of our Basic Security and
					Advanced Security packages to find the best fit for your
					needs. Whether you're looking for robust protection or
					enhanced capabilities, Wolvy offers solutions tailored to
					your content's security.
				</p>
				<div className="flex *:flex-1 mt-5 gap-10 md:flex-row flex-col">
					<div className="flex flex-col gap-5 p-5 bg-yellow *:text-black">
						<p className="text-2xl font-bold">Standard</p>
						{standard.map((f, i) => (
							<p key={i}>{f}</p>
						))}
					</div>
					<div className="flex flex-col gap-5 p-5 bg-purplePrimary">
						<p className="text-2xl font-bold">Advanced</p>
						{advanced.map((f, i) => (
							<p key={i}>{f}</p>
						))}
					</div>
				</div>
				<div className="flex *:flex-1 mt-10">
					<FAQ />
				</div>
			</div>
			<NeedHelp />
		</div>
	);
};

export default SecurityComparison;
