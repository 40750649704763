import React from "react";
import FAQItem from "./FAQItem";

const FAQ = () => {
	const aq = [
		{
			q: "What makes Wolvy different from other video security platforms?",
			a: "Wolvy combines advanced DRM technology, real-time analytics, and seamless content delivery. It ensures top-notch security while providing a user-friendly experience and scalability for all your video needs.",
		},
		{
			q: "Can Wolvy protect my videos from being screen-recorded?",
			a: "Yes! Wolvy includes advanced screen grab protection to block screen recording and unauthorized captures on supported devices and platforms.",
		},
		{
			q: "How do I integrate Wolvy with my existing website or platform?",
			a: "Integration is simple. Wolvy provides easy-to-follow guides and APIs that allow you to embed your videos securely on any platform with minimal effort.",
		},
		{
			q: "What type of content can I protect using Wolvy?",
			a: "Wolvy secures a variety of video content, including films, training materials, live streams, and educational videos, ensuring that your intellectual property remainsprotected.",
		},
		{
			q: "Does Wolvy support 4K and other high-resolution video formats?",
			a: "Absolutely! Wolvy supports encoding and streaming in up to 4K resolution, ensuring a high-quality experience for your audience.",
		},
		{
			q: "What is DRM, and why do I need it for my videos?",
			a: "DRM (Digital Rights Management) is a technology that prevents unauthorized access to your videos. With DRM, Wolvy ensures your content is protected from downloads, piracy, and misuse",
		},
		{
			q: "How can I monitor my video performance with Wolvy?",
			a: "Wolvy offers real-time analytics, allowing you to track viewership, engagement, and location-based data to optimize your content and strategy.",
		},
		{
			q: "What happens if I exceed my storage or bandwidth limits?",
			a: "Wolvy will notify you as you approach your limits, and you can easily upgrade your plan to ensure uninterrupted service.",
		},
		{
			q: "Is there customer support available if I encounter issues?",
			a: "Yes! Wolvy provides dedicated customer support to assist you with any issues or questions you may have.",
		},
		{
			q: "What devices and platforms are compatible with Wolvy?",
			a: "Wolvy is compatible with all major browsers, devices, and platforms, including Windows, macOS, iOS, Android, and more. It supports Widevine and FairPlay DRM for extensive coverage.",
		},
	];
	return (
		<div className="flex md:flex-row flex-col *:flex-1">
			<div>
				<p className="text-5xl md:max-w-[60%] max-w-full font-bold">
					Frequently asked question
				</p>
				<div className="flex flex-col mt-5">
					{aq.map((e, i) => (
						<>
							<FAQItem title={e.q} description={e.a} />
							{i + 1 !== aq.length && (
								<div className="h-0.5 w-full bg-gray"></div>
							)}
						</>
					))}
				</div>
			</div>
			<div className="flex justify-center items-center object-contain">
				<img
					src="/images/question-section.webp"
					className="h-60 object-contain"
					alt="Video"
				/>
			</div>
		</div>
	);
};

export default FAQ;
