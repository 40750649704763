import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getLangCode } from "../utils/getLangCode";
import { getUser } from "../utils/getUser";
import { getAPIKey } from "../utils/getAPIKey";

const UploadSubtitle = ({ videoID, setSubtitle }) => {
	const [done, setDone] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [file, setFile] = useState(null);
	const uploadSubtitle = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(false);
		const label = e.target.label.value;
		const countryCode = e.target.countryCode.value;
		const file = e.target.file.files[0];
		const user = getUser();
		const apiKey = getAPIKey();
		if (
			!label ||
			label === "" ||
			!countryCode ||
			countryCode === "" ||
			!file
		) {
			setError(true);
			setLoading(false);
			return;
		}
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("video_id", videoID);
		formData.append("language_code", countryCode);
		formData.append("label", label);
		formData.append("file", file);

		axios
			.post("/add_caption.php", formData)
			.then((res) => {
				if (res.data.status === "success") {
					setLoading(false);
					setDone(true);
				} else {
					setError(true);
				}
			})
			.catch(() => {
				setError(true);
			});
	};
	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);
	const subtitleRef = useRef(null);
	const languages = getLangCode();
	return (
		<div className="fixed top-0 left-0 h-screen w-screen z-50 flex justify-center items-center bg-white/50">
			<div className="w-full md:w-1/3 bg-[#d4bdf1] shadow-[15px_15px_0_0_#3f1f61] border-2 border-purpleSecondary flex flex-col">
				<div className="flex bg-purplePrimary justify-end p-5 border-b-2 border-purpleSecondary">
					<img
						src="/images/o-x.webp"
						className="h-6 cursor-pointer select-none"
						alt="x-o"
						onClick={() => setSubtitle(false)}
					/>
				</div>
				<div className="h-full py-10 flex justify-center items-center flex-col">
					<form onSubmit={uploadSubtitle} className="w-10/12">
						<div className="flex flex-col gap-5">
							<div className="mx-auto">
								<img
									src="/images/upload.svg"
									alt="Upload"
									className="h-32"
									onClick={() => subtitleRef.current?.click()}
								/>
							</div>
							<input
								type="file"
								name="file"
								hidden
								accept=".vtt,.srt"
								ref={subtitleRef}
								onChange={(e) =>
									setFile(e.target.files[0].name)
								}
							/>
							<p className="text-black text-center">
								{file
									? file
									: "Supported formats are .VTT and .SRT"}
							</p>
						</div>
						<div className="flex gap-5 mt-5">
							<input
								type="text"
								name="label"
								className="text-black placeholder:text-black p-2 h-10 w-32 border-[1px] border-purpleSecondary bg-transparent"
								placeholder="Label"
							/>
							<select
								name="countryCode"
								className="w-full bg-transparent text-black *:text-black border-[1px] border-purpleSecondary"
							>
								<option selected hidden value="">
									Language Code
								</option>
								{languages.map((lang) => (
									<option value={lang.ShortCode}>
										{lang.Name}
									</option>
								))}
							</select>
							<div>
								<button
									type="submit"
									disabled={loading}
									className="aspect-square h-full border-[1px] border-purpleSecondary button-lg flex justify-center items-center !bg-yellow"
								>
									<FontAwesomeIcon
										icon={faCheck}
										className="*:text-black"
									/>
								</button>
							</div>
						</div>
					</form>
					{error && (
						<p className="text-center mt-2 text-pink">
							Label, Language Code and File is required
						</p>
					)}
					{done && (
						<p className="text-center mt-2 text-green">
							Subtitle uploaded successfully!
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default UploadSubtitle;
