import React, { useContext, useEffect, useState } from "react";
import { Uploading } from "../context/UploadingContext";
import Progress from "./Progress";
import { useLocation } from "react-router-dom";
import Drag from "./Drag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const GlobalUploader = () => {
	const { uploadProgress, filesUploading, currentFile } =
		useContext(Uploading);
	const location = useLocation();
	const pathname = location.pathname;
	const [minized, setMinized] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(window.matchMedia("(max-width: 768px)").matches);
		};

		checkMobile();
		window.addEventListener("resize", checkMobile);

		return () => {
			window.removeEventListener("resize", checkMobile);
		};
	}, []);
	if (filesUploading.length && pathname !== "/folders") {
		return !isMobile ? (
			<Drag minized={minized}>
				<div className="relative shadow-[10px_10px_0_0_#3f1f61] my-5 p-5 bg-lavander [&_*]:text-purpleSecondary overflow-hidden !cursor-pointer *:!cursor-pointer">
					<div className="flex items-center justify-between">
						<p className="font-bold text-xl">
							Uploading Now: {filesUploading[currentFile]?.name} |
							({currentFile + 1}/{filesUploading.length})
						</p>
						<div
							onClick={() => setMinized(!minized)}
							className="p-2"
						>
							<FontAwesomeIcon
								icon={minized ? faChevronUp : faChevronDown}
								className="cursor-pointer"
							/>
						</div>
					</div>
					{!minized && (
						<div className="relative">
							<Progress
								height={20}
								width={uploadProgress}
								background="#7e3dd9"
								icon={true}
							/>
							<p className="center-absolute !text-white text-xl cursor-pointer">
								{uploadProgress}%
							</p>
						</div>
					)}
				</div>
			</Drag>
		) : (
			<div className="fixed bottom-0 left-0 w-full p-5 bg-gray overflow-hidden !cursor-pointer *:!cursor-pointer">
				<div className="flex items-center justify-between">
					<p className="font-bold text-xl">
						Uploading Now: {filesUploading[currentFile]?.name} | (
						{currentFile + 1}/{filesUploading.length})
					</p>
					<div onClick={() => setMinized(!minized)} className="p-2">
						<FontAwesomeIcon
							icon={minized ? faChevronUp : faChevronDown}
							className="cursor-pointer"
						/>
					</div>
				</div>
				{!minized && (
					<div className="relative">
						<Progress
							height={20}
							width={uploadProgress}
							background="#7e3dd9"
							icon={true}
						/>
						<p className="center-absolute text-xl cursor-pointer">
							{uploadProgress}%
						</p>
					</div>
				)}
			</div>
		);
	} else {
		return <></>;
	}
};

export default GlobalUploader;
