const plans = {
	standard: [
		{
			id: 1,
			name: "Plan 1",
			storage: 200,
			bandwidth: 2500,
			price: 240,
		},
		{
			id: 2,
			name: "Plan 2",
			storage: 500,
			bandwidth: 10000,
			price: 600,
		},
		{
			id: 3,
			name: "Plan 3",
			storage: 1000,
			bandwidth: 20000,
			price: 1000,
		},
	],
	advanced: [
		{
			id: 3,
			name: "Plan 3",
			storage: 200,
			bandwidth: 2500,
			price: 540,
		},
		{
			id: 4,
			name: "Plan 4",
			storage: 500,
			bandwidth: 10000,
			price: 900,
		},
		{
			id: 5,
			name: "Plan 5",
			storage: 1000,
			bandwidth: 20000,
			price: 1800,
		},
	],
};

export const getPlans = () => plans;

export const calculateCustomPlanPrice = (storage, bandwidth, active) => {
	const plans = {
		standard: [
			{ storage: 200, bandwidth: 2500, price: 240 },
			{ storage: 500, bandwidth: 10000, price: 600 },
			{ storage: 1000, bandwidth: 20000, price: 1000 },
			// { storage: 1200, bandwidth: 25000, price: 1800 },
			// { storage: 1700, bandwidth: 30000, price: 2500 },
			{ storage: 2000, bandwidth: 40000, price: 2000 },
		],
		advanced: [
			{ storage: 200, bandwidth: 2500, price: 540 },
			{ storage: 500, bandwidth: 10000, price: 900 },
			{ storage: 1000, bandwidth: 20000, price: 1800 },
			// { storage: 1200, bandwidth: 25000, price: 3000 },
			// { storage: 1700, bandwidth: 30000, price: 3600 },
			{ storage: 2000, bandwidth: 40000, price: 3600 },
		],
	};

	// Validate the active plan type
	if (!plans[active]) {
		throw new Error("Invalid plan type. Use 'standard' or 'advanced'.");
	}

	const plan = plans[active];

	// Helper function to interpolate prices
	function interpolatePrice(storageGB, bandwidthGB) {
		let previous = plan[0];
		for (let i = 1; i < plan.length; i++) {
			const current = plan[i];
			if (
				storageGB <= current.storage &&
				bandwidthGB <= current.bandwidth
			) {
				const storageRatio =
					(storageGB - previous.storage) /
					(current.storage - previous.storage);
				const bandwidthRatio =
					(bandwidthGB - previous.bandwidth) /
					(current.bandwidth - previous.bandwidth);
				const avgRatio = (storageRatio + bandwidthRatio) / 2;
				return (
					previous.price + avgRatio * (current.price - previous.price)
				);
			}
			previous = current;
		}

		// Extrapolation logic for storage or bandwidth above the maximum range
		const last = plan[plan.length - 1];
		const secondLast = plan[plan.length - 2];

		// Calculate per-unit price increments
		const storagePriceIncrement =
			(last.price - secondLast.price) /
			(last.storage - secondLast.storage);
		const bandwidthPriceIncrement =
			(last.price - secondLast.price) /
			(last.bandwidth - secondLast.bandwidth);

		const extraStoragePrice = Math.max(
			0,
			(storageGB - last.storage) * storagePriceIncrement
		);
		const extraBandwidthPrice = Math.max(
			0,
			(bandwidthGB - last.bandwidth) * bandwidthPriceIncrement
		);

		// Add extra costs to the last plan's price
		return last.price + extraStoragePrice + extraBandwidthPrice;
	}

	// Calculate bandwidth in TB since plans use TB for bandwidth

	return interpolatePrice(
		storage / Math.pow(10, 9),
		bandwidth / Math.pow(10, 9)
	).toFixed(2);
};
