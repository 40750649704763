import React, { useRef, useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import FoldersSection from "../components/Videos/FoldersSection";
import VideosSection from "../components/Videos/VideosSection";
import Alert from "../components/Alert";

const getPlan = (result) => {
	if (result[2].status === "error") {
		return null;
	} else {
		return result[2].data;
	}
};

const Videos = () => {
	const uploader = useRef(null);
	const result = useLoaderData();
	const plan = getPlan(result);

	const [activeMenu, setActiveMenu] = useState(null);
	const toggleFolderMenu = () => {
		setActiveMenu((prev) => (prev === "folder" ? null : "folder"));
	};

	const toggleVideoMenu = () => {
		setActiveMenu((prev) => (prev === "video" ? null : "video"));
	};

	const isFolderMenuVisible = activeMenu === "folder";
	const isVideoMenuVisible = activeMenu === "video";

	const [moving, setMoving] = useState([]);

	const [fileDragged, setFileDragged] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const status = searchParams.get("status");
	const [showStatus, setShowStatus] = useState(true);

	const [uplaodDone, setUplaodDone] = useState(false);
	const [selectionMode, setSelectionMode] = useState(true);
	const [selections, setSelections] = useState([]);
	// throw new Error("An error occurred in Videos.js");
	return (
		<div
			className="h-full bg-purpleSecondary"
			onClick={() => setActiveMenu(null)}
		>
			<div className="md:p-7 p-3 border-b-[1px] border-b-gray">
				<p className="text-4xl ">Videos</p>
			</div>
			{showStatus &&
				(status === "done" ? (
					<Alert
						setAlert={setShowStatus}
						message={`Videos deleted successfully`}
					/>
				) : status === "error" ? (
					<Alert
						setAlert={setShowStatus}
						message={`An error occurred, Please try again!`}
					/>
				) : (
					""
				))}
			{uplaodDone ? (
				<>
					<Alert
						setAlert={setUplaodDone}
						message={`Videos uploaded! You don't need to stay in the page no
					longer while processing the videos`}
					/>
				</>
			) : (
				""
			)}
			<div className="md:p-7 p-3">
				<FoldersSection
					uploader={uploader}
					result={result}
					isMenuVisible={isFolderMenuVisible}
					setMenuVisible={toggleFolderMenu}
					fileDragged={fileDragged}
					moving={moving}
					plan={plan}
					setUplaodDone={setUplaodDone}
					setSelectionMode={setSelectionMode}
					setSelections={setSelections}
				/>
				<VideosSection
					result={result}
					uploader={uploader}
					isMenuVisible={isVideoMenuVisible}
					setMenuVisible={toggleVideoMenu}
					setMoving={setMoving}
					setFileDragged={setFileDragged}
					plan={plan}
					setUplaodDone={setUplaodDone}
					selectionMode={selectionMode}
					selections={selections}
					setSelections={setSelections}
				/>
			</div>
		</div>
	);
};

export default Videos;
