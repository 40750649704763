import React, { useState } from "react";

const Alert = ({
	handleAction,
	names,
	action = "default",
	setAlert,
	message,
}) => {
	const [loading, setLoading] = useState(false);
	const handle = async () => {
		setLoading(true);
		await handleAction();
		setLoading(false);
	};
	return (
		<div className="fixed top-0 left-0 h-screen w-screen z-50 flex justify-center items-center bg-white/50">
			<div className="w-full md:w-1/3 bg-[#d4bdf1] shadow-[15px_15px_0_0_#3f1f61] border-2 border-purpleSecondary flex flex-col">
				<div className="flex bg-purplePrimary justify-end p-5 border-b-2 border-purpleSecondary">
					<img
						src="/images/o-x.webp"
						className="h-6 cursor-pointer select-none"
						alt="x-o"
						onClick={() => setAlert(false)}
					/>
				</div>
				<div className="h-full py-10 flex justify-center items-center flex-col">
					<div className="flex items-center gap-5 mx-5 w-full px-3">
						<img
							src="/images/warning.webp"
							className="h-20"
							alt="Warning"
						/>
						<p className="text-black text-lg overflow-auto max-w-[90%] break-words">
							{action === "delete"
								? `You really want to delete "${names?.join(
										", "
								  )}
								"?`
								: message}
						</p>
					</div>
					<div className="flex justify-evenly mt-5 gap-10">
						{action === "delete" ? (
							<>
								<button
									disabled={loading}
									className="button-lg !bg-[#9971d1] text-black py-4 px-10"
									onClick={handle}
								>
									{loading ? "LOADING..." : "YES"}
								</button>
								<button
									className="button-lg !bg-pink text-black py-4 px-10"
									onClick={() => setAlert(false)}
								>
									NO
								</button>
							</>
						) : (
							<button
								className="button-lg !bg-green text-black py-4 px-10"
								onClick={() => setAlert(false)}
							>
								OK
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Alert;
