import React, { useState } from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import GlobalUploader from "./components/GlobalUploader";
import ScrollToTop from "./components/ScrollToTop";

const Layout = () => {
	const [menu, setMenu] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const avatarData = useLoaderData();
	return (
		<>
			<ScrollToTop />
			<div
				className={`min-h-screen flex ${
					menu && "h-screen overflow-hidden"
				}`}
				onClick={() => setShowSearch(false)}
			>
				<div
					className={`lg:flex-[2] xl:flex-[1] ${
						menu ? "w-full" : "w-0"
					} overflow-hidden md:sticky absolute top-0 h-screen z-50`}
				>
					<Sidebar menu={menu} setMenu={setMenu} />
				</div>
				<div className="lg:flex-[8] xl:flex-[6] w-full overflow-hidden flex flex-col">
					<Navbar
						showSearch={showSearch}
						setShowSearch={setShowSearch}
						avatarData={avatarData}
						setMenu={setMenu}
					/>
					<Outlet />
				</div>
			</div>
			<GlobalUploader />
		</>
	);
};

export default Layout;
