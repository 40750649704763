import React, { useState } from "react";
import Plan from "./Plan";
import { Link } from "react-router-dom";
import { calculateCustomPlanPrice, getPlans } from "../utils/plans";

const Plans = ({
	planWord = false,
	contactUsSection = false,
	setCurrentPlan = false,
	userPlan = null,
}) => {
	const [plan, setPlan] = useState("standard");
	const plans = getPlans();

	const customPrice = (storage, bandwidth) => {
		return calculateCustomPlanPrice(storage, bandwidth, plan);
	};

	return (
		<div className="md:p-7 p-3">
			<div className="flex justify-between items-center md:p-0 px-2">
				<p className=" font-bold text-xl">{planWord ? "Plans" : ""}</p>
				<div className="flex gap-2 items-center">
					<p>Standard</p>
					<div
						className="h-5 w-10 p-2 bg-purplePrimary relative cursor-pointer"
						onClick={() =>
							setPlan((plan) =>
								plan === "standard" ? "advanced" : "standard"
							)
						}
					>
						<div
							className={`absolute top-1/2 -translate-y-1/2 ${
								plan === "standard" ? "left-1" : "right-1"
							} h-3 w-3 bg-purpleSecondary`}
						></div>
					</div>
					<p>Advanced</p>
				</div>
			</div>
			<div className="mt-5 md:p-5 p-2 bg-gray">
				<div className="flex flex-col gap-5">
					{plans[plan].map((p) => (
						<Plan
							key={p.id}
							id={p.id}
							price={p.price}
							name={p.name}
							storage={p.storage * Math.pow(10, 9)}
							bandwidth={p.bandwidth * Math.pow(10, 9)}
							setCurrentPlan={setCurrentPlan}
							plan={plan}
							userPlan={userPlan}
						/>
					))}
				</div>
				<p className="my-10 text-2xl  text-white font-bold">
					Customize Plan
				</p>
				<Plan
					id="custom"
					name="Customized Plan"
					customize={true}
					setCurrentPlan={setCurrentPlan}
					plan={plan}
					min={100 * Math.pow(10, 9)}
					max={2000 * Math.pow(10, 9)}
					min2={1000 * Math.pow(10, 9)}
					max2={40000 * Math.pow(10, 9)}
					step1={20 * Math.pow(10, 9)}
					step2={500 * Math.pow(10, 9)}
					setPrice={customPrice}
					planType={plan}
					userPlan={userPlan}
				/>
				{contactUsSection && (
					<p className="mt-10 mb-5 text-2xl  text-white font-bold">
						High Usage?{" "}
						<Link to="/contact-us" className="text-purplePrimary">
							Contact Us
						</Link>{" "}
						for special price
					</p>
				)}
			</div>
		</div>
	);
};

export default Plans;
