import React, { useEffect, useState } from "react";
import Progress from "./Progress";
import axios from "axios";
import { formatBytes } from "../utils/formatBytes";
import { getUser } from "../utils/getUser";
import { getAPIKey } from "../utils/getAPIKey";

const Status = () => {
	const [data, setData] = useState(null);
	useEffect(() => {
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData?.append("user_id", user);
		formData?.append("api_key", apiKey);
		axios.post("/get_usage.php", formData).then((res) => {
			if (res.data?.status === "success") {
				setData(res.data);
			}
		});
	}, []);
	if (data) {
		return (
			<div className="flex justify-center gap-5 flex-wrap md:px-7 px-3">
				<div className="flex-1">
					<p className=" text-lg font-bold mb-2">Storage Usage</p>
					<div className="p-5 pb-0 bg-gray flex flex-col gap-3">
						<p className=" font-bold text-sm">
							Total Storage: {formatBytes(data?.storage.limit)}
						</p>
						<Progress
							width={
								(data?.storage.available /
									data?.storage.limit) *
								100
							}
							background={"#7E3DD9"}
						/>
						<div className="border-t-[1px] border-t-grayLight flex">
							<div className="flex-1">
								<div className="pt-3">
									<p className="">Used Storage</p>
									<p className=" text-2xl font-bold">
										{formatBytes(data?.storage.used)}
									</p>
								</div>
							</div>
							<div className="flex-1 border-l-2 border-l-grayLight">
								<div className="p-3">
									<p className="">Available Storage</p>
									<p className=" text-2xl font-bold">
										{formatBytes(data?.storage.available)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-1">
					<p className=" text-lg font-bold mb-2">Bandwidth Usage</p>
					<div className="p-5 pb-0 bg-gray flex flex-col gap-3">
						<p className=" font-bold text-sm">
							Total Bandwidth:{" "}
							{formatBytes(data?.bandwidth.limit)}
						</p>
						<Progress
							width={
								(data?.bandwidth.available /
									data?.bandwidth.limit) *
								100
							}
							background={"#f7ee7f"}
						/>
						<div className="border-t-[1px] border-t-grayLight flex">
							<div className="flex-1">
								<div className="pt-3">
									<p className="">Used Bandwidth</p>
									<p className=" text-2xl font-bold">
										{formatBytes(data?.bandwidth.used)}
									</p>
								</div>
							</div>
							<div className="flex-1 border-l-2 border-l-grayLight">
								<div className="p-3">
									<p className="">Available Bandwidth</p>
									<p className=" text-2xl font-bold">
										{formatBytes(data?.bandwidth.available)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return "";
	}
};

export default Status;
