import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ContextMenu from "./ContextMenu";
import { getUser } from "../../utils/getUser";
import axios from "axios";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../Pagination";
import FolderItem from "../FolderItem";
import { checkFileClicked } from "../../utils/checkFileClicked";
import { useNavigate } from "react-router-dom";
import Alert from "../Alert";
import { getAPIKey } from "../../utils/getAPIKey";

const FoldersSection = ({
	uploader,
	result,
	isMenuVisible,
	setMenuVisible,
	fileDragged,
	moving,
	plan,
}) => {
	const [createFolder, setCreateFolder] = useState(false);
	const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
	const [foldersLoading, setFoldersLoading] = useState(false);
	const [createFoldersLoading, setCreateFoldersLoading] = useState(false);
	const [createFoldersError, setCreateFoldersError] = useState(false);
	const [folders, setFolders] = useState(result[0]);
	const [error, setError] = useState(false);
	const [folderActive, setFolderActive] = useState(false);

	const navigate = useNavigate();
	const [flipped, setFlipped] = useState(false);

	const folderContextMenu = (e) => {
		e.preventDefault();
		setMenuPosition({ x: e.pageX, y: e.pageY });
		if (e.pageX + 288 > document.body.clientWidth) {
			setFlipped(true);
		} else {
			setFlipped(false);
		}
		setMenuVisible();
		const id = checkFileClicked(e, "folder-item", "folder-container");

		if (id) {
			setFolderActive(id);
		} else {
			setFolderActive(false);
		}
	};

	const updateFolder = (page) => {
		setFoldersLoading(true);
		setError(false);
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("page", page);
		formData.append("limit", 8);
		axios
			.post("/list_folders.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setFolders(response.data);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setFoldersLoading(false);
			});
	};

	const handleCreateFolder = (e) => {
		e.preventDefault();
		setCreateFoldersLoading(true);
		setCreateFoldersError(false);
		const folderName = e.target.name.value;
		if (!folderName) {
			setCreateFoldersLoading(false);
			return;
		}
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("folder_name", folderName);
		axios
			.post("/create_folder.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					navigate(0);
				} else {
					setCreateFoldersError(
						"An error occurred, Please try again!"
					);
				}
			})
			.catch(() => {
				setCreateFoldersError("An error occurred, Please try again!");
			})
			.finally(() => {
				setCreateFoldersLoading(false);
			});
	};

	const [alert, setAlert] = useState(false);
	const [alertAction, setAlertAction] = useState(null);
	const handleDelete = async () => {
		const { id } = folderActive;
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("folder_id", id);
		try {
			const response = await axios.post("/delete_folder.php", formData);
			if (response.data.status === "success") {
				setAlert(false);
				setFolders((folder) => ({
					...folder,
					folders: folder.folders.filter((f) => f.id !== id),
				}));
			} else {
			}
		} catch (error) {}
	};

	return (
		<>
			<div className="mb-2 flex justify-between md:items-center items-start gap-5 md:flex-row flex-col">
				<p className="font-bold text-xl">Folders</p>
				<div className="flex gap-5 md:flex-row flex-col md:w-auto w-full">
					{/* <div
						className="button-lg !bg-green flex justify-center items-center *:text-black gap-2 px-10 py-2"
						onClick={() => {
							setSelectionMode((select) => {
								if (select) {
									setSelections([]);
								}
								return !select;
							});
						}}
					>
						<FontAwesomeIcon
							icon={faSquareCheck}
							className="text-xl *:text-black"
						/>
						<p>SELECT VIDEOS</p>
					</div> */}
					{plan && (
						<div
							className="button-lg !bg-yellow flex justify-center items-center *:text-black gap-2 px-14 py-2"
							onClick={() => {
								uploader.current.click();
							}}
						>
							<img
								src="/images/upload-icon.svg"
								alt="Upload"
								className="h-5 brightness-0 saturate-100"
							/>
							<p>UPLOAD</p>
						</div>
					)}
					<div
						className="button-lg !bg-green flex justify-center items-center *:text-black gap-2 px-10 py-2"
						onClick={() => {
							setCreateFolder(!createFolder);
						}}
					>
						<FontAwesomeIcon
							icon={faPlus}
							className="text-xl *:text-black"
						/>
						<p>CREATE FOLDER</p>
					</div>
					{createFolder && (
						<form
							className="flex gap-5"
							onSubmit={handleCreateFolder}
						>
							<input
								name="name"
								placeholder="Folder Name"
								className="md:h-full h-10 px-5 bg-grayLight text-white"
							/>
							<button
								type="submit"
								className="md:h-full w-12 button-lg flex justify-center items-center"
								disabled={createFoldersLoading}
							>
								<FontAwesomeIcon icon={faCheck} />
							</button>
						</form>
					)}
				</div>
			</div>
			{createFoldersError && (
				<div className="flex justify-end">
					<p className="text-pink text-sm">{createFoldersError}</p>
				</div>
			)}
			<div className="bg-gray">
				{isMenuVisible && (
					<ContextMenu
						folderActive={folderActive}
						moving={moving}
						menuPosition={menuPosition}
						flipped={flipped}
						setAlert={setAlert}
						setAlertAction={setAlertAction}
					/>
				)}
				<div className="relative p-5">
					{foldersLoading && (
						<div className="absolute z-50 top-0 left-0 h-full w-full bg-white/50 flex justify-center items-center">
							<p className="text-black">Loading ....</p>
						</div>
					)}

					{!folders.folders.length && (
						<p className="py-10 text-center font-bold  text-2xl">
							No Folders created yet.
						</p>
					)}
					<div
						className="relative folders-container grid xl:grid-cols-8 lg:grid-cols-7 md:grid-cols-5 grid-cols-2 gap-y-10"
						onContextMenu={folderContextMenu}
					>
						{folders.folders.map((f) => (
							<FolderItem
								key={f.id}
								id={f.id}
								name={f.name}
								status={
									f.has_files
										? "full-folder.svg"
										: "folder.svg"
								}
								fileDragged={fileDragged}
								setAlert={setAlert}
								setAlertAction={setAlertAction}
							/>
						))}
					</div>
					{folders.pagination.total_pages > 1 && (
						<Pagination
							pages={folders.pagination.total_pages}
							current={folders.pagination.current_page}
							update={updateFolder}
						/>
					)}
					{error && <p className="text-pink">{error}</p>}
				</div>
			</div>
			{alert ? (
				<Alert
					handleAction={() => {
						alertAction === "delete" && handleDelete();
					}}
					setAlert={setAlert}
					action={alertAction}
					names={[folderActive.title]}
				/>
			) : (
				""
			)}
		</>
	);
};

export default FoldersSection;
