import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { decode } from "../utils/crypto";
import { getAPIKey } from "../utils/getAPIKey";

const Auth = () => {
	const [user, setUser] = useState(null);
	const apiKey = getAPIKey();

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const part1 = sessionStorage.getItem("session");
		const part2 = sessionStorage.getItem("vid");
		const decoded = decode(part1, part2);
		if (decoded && decoded !== "Error") {
			setUser(decoded);
		} else {
			sessionStorage.removeItem("session");
			sessionStorage.removeItem("vid");
			sessionStorage.removeItem("_etg");
			setUser("No User");
		}
		if (apiKey === null) {
			sessionStorage.removeItem("session");
			sessionStorage.removeItem("vid");
			sessionStorage.removeItem("_etg");
			setUser("No User");
		}
	}, []);

	useEffect(() => {
		if (user === "No User" || apiKey === null) {
			navigate(`/login?redirect=${location.pathname}`);
		}
	}, [user, navigate]);

	if (user === null) {
		return <div>Loading...</div>;
	}

	if (user !== "No User" && apiKey !== null) {
		return <Outlet />;
	}

	return null;
};

export default Auth;
