import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { getUser } from "../../utils/getUser";
import { formatBytes } from "../../utils/formatBytes";
import Alert from "../Alert";
import { getAPIKey } from "../../utils/getAPIKey";

const Payment = ({ currentPlan, setCurrentPlan }) => {
	const [paymentSelect, setpaymentSelect] = useState("");
	const [coupon, setCoupon] = useState(0);
	const [couponLoding, setCouponLoding] = useState(false);
	const [couponDone, setCouponDone] = useState(false);
	const [couponError, setCouponError] = useState(false);
	const [paymentLoding, setPaymentLoding] = useState(false);
	const [paymentDone, setPaymentDone] = useState(false);
	const [paymentError, setPaymentError] = useState(false);
	const [couponName, setCouponName] = useState(false);
	const [alert, setAlert] = useState(false);
	const [message, setMessage] = useState("");

	const handleCoupon = (e) => {
		e.preventDefault();
		setCouponLoding(true);
		setCouponDone(false);
		setCouponError(false);
		const coupon = e.target.coupon.value;
		if (!coupon) {
			setCouponError("Coupon is required!");
			return;
		}
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("plan_id", currentPlan.id);
		formData.append("coupon_code", coupon);
		axios
			.post("/vaildate_coupon.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setCouponDone(true);
					setCoupon(response.data.data);
					setCouponName(coupon);
				} else {
					setCouponError("Invalid coupon code.");
				}
			})
			.catch(() => {
				setCouponError("An error occurred, Please try again!");
			})
			.finally(() => {
				setCouponLoding(false);
			});
	};

	const getDiscountValue = () => {
		if (coupon.type === "percentage") {
			return coupon.amount * (1 / 100) * Number(currentPlan.price);
		} else {
			return coupon.amount;
		}
	};

	const handlePayment = () => {
		if (!paymentLoding) {
			if (paymentSelect !== "fawry" && paymentSelect !== "cards") {
				setPaymentError("You must select a payment gateway!");
				return;
			}
			const price = getDiscountValue()
				? currentPlan.price - getDiscountValue()
				: currentPlan.price;
			setPaymentLoding(true);
			setMessage("");
			setAlert(false);
			setPaymentError(false);
			const user = getUser();
			const apiKey = getAPIKey();
			const formData = new FormData();
			formData.append("user_id", user);
			formData.append("api_key", apiKey);
			formData.append("amount", price);
			formData.append("gateway", paymentSelect);
			formData.append("plan_type", currentPlan.type);
			formData.append("isAdvanced", currentPlan.plan);
			couponName && formData.append("coupon_code", couponName);
			formData.append("storage", currentPlan.storage);
			formData.append("bandwidth", currentPlan.bandwidth);
			axios
				.post("/create_payment.php", formData)
				.then((response) => {
					if (response.data.status === "success") {
						const gatewayResponse =
							response.data.data.gateway_response;
						if (gatewayResponse) {
							if (paymentSelect === "fawry") {
								setMessage(
									`Pay with your refrence code ${gatewayResponse} at nearest Fawry branch.`
								);
								setAlert(true);
							} else {
								window.location.href = gatewayResponse;
							}
						} else {
							setPaymentError(
								"An error occurred, Please try again!"
							);
							return;
						}
					} else {
						setPaymentError("An error occurred, Please try again!");
					}
				})
				.catch(() => {
					setPaymentError("An error occurred, Please try again!");
				})
				.finally(() => {
					setPaymentLoding(false);
				});
		}
	};

	return (
		<div className="md:p-7 p-3">
			<div className="flex justify-between items-center">
				<p className=" font-bold text-xl">Payment</p>
			</div>
			<div className="mt-5 p-5 py-10 bg-gray flex justify-between gap-10 *:font-bold *: *:text-xl *:flex-1 xl:flex-row flex-col">
				<div className="flex gap-5 flex-col">
					<p>Plan: {currentPlan.name}</p>
					<p>Storage: {formatBytes(currentPlan.storage)}</p>
					<p>Bandwidth: {formatBytes(currentPlan.bandwidth)}</p>
					<div className="flex md:items-center items-start gap-10 md:flex-row flex-col">
						<p>Coupon Code:</p>
						<form
							className="flex gap-2 justify-stretch"
							onSubmit={handleCoupon}
						>
							<input
								type="text"
								name="coupon"
								required
								className="text-white font-normal text-lg px-3 bg-transparent border-2 border-grayLight"
							/>
							<button
								type="submit"
								disabled={couponLoding}
								className="button h-10 w-10 flex justify-center items-center !bg-green"
							>
								<FontAwesomeIcon icon={faCheck} />
							</button>
						</form>
					</div>
					{couponError && <p className="text-pink">{couponError}</p>}
					{couponDone && (
						<p className="text-green">Coupon has been added!</p>
					)}
					<p className="mt-10">Sub Total: {currentPlan.price}$</p>
					{coupon ? <p>Coupon Code: -{getDiscountValue()}$</p> : ""}
					{coupon ? (
						<p>Total: {currentPlan.price - getDiscountValue()}$</p>
					) : (
						""
					)}
				</div>
				<div className="flex flex-col gap-7">
					<p>Payment Gateway</p>
					<img
						className={`${
							paymentSelect === "fawry" && "drop-shadow-active"
						} w-44 cursor-pointer`}
						src="/images/fawry.webp"
						alt="Fawry"
						onClick={() => setpaymentSelect("fawry")}
					/>
					<img
						className={`${
							paymentSelect === "cards" && "drop-shadow-active"
						} w-44 cursor-pointer`}
						src="/images/visa.webp"
						alt="cards"
						onClick={() => setpaymentSelect("cards")}
					/>
					<p className="mt-10 text-sm ">
						* You will be redirected to the the payment gateway to
						finalize your paymenth then the plan will be activated
						automatically.
					</p>
					<div className="flex gap-10 items-center">
						<div
							className="w-52 py-3 button !bg-green"
							onClick={handlePayment}
						>
							<p className="text-lg font-normal  text-center">
								{paymentLoding ? "LOADING..." : "$ PAY NOW"}
							</p>
						</div>
						<div
							className="w-52 py-3 button !bg-pink"
							onClick={() => setCurrentPlan(null)}
						>
							<p className="text-lg font-normal  text-center text-black flex items-center gap-2 justify-center">
								<FontAwesomeIcon
									icon={faTimes}
									className="*:text-black"
								/>
								Cancel
							</p>
						</div>
					</div>
					{paymentError && (
						<p className="text-pink">{paymentError}</p>
					)}
					{paymentDone && <p className="text-green">{paymentDone}</p>}
				</div>
			</div>
			{alert ? <Alert message={message} setAlert={setAlert} /> : ""}
		</div>
	);
};

export default Payment;
