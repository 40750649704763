import React, { useState, useRef, useEffect } from "react";

const Drag = (props) => {
	const { children } = props;
	const { minized } = props;
	const [pos, setPos] = useState({ x: 0, y: 0 });
	const dragRef = useRef(null);

	const onMouseDown = (e) => {
		e.preventDefault();
		const startX = e.clientX - pos.x;
		const startY = e.clientY - pos.y;

		const onMouseMove = (e) => {
			setPos({
				x: e.clientX - startX,
				y: e.clientY - startY,
			});
		};

		const onMouseUp = () => {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("mouseup", onMouseUp);
		};

		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);
	};

	useEffect(() => {
		setPos({
			x: window.innerWidth / 2,
			y: window.innerHeight,
		});
	}, []);

	return (
		<div
			{...props}
			ref={dragRef}
			onMouseDown={onMouseDown}
			className={`fixed ${
				minized ? "w-auto" : "w-1/2"
			} z-50 -translate-x-1/2 -translate-y-full min-w-80`}
			style={{
				left: `${pos.x}px`,
				top: `${pos.y}px`,
				...props.style,
			}}
		>
			{children}
		</div>
	);
};

export default Drag;
