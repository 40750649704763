import React from "react";
import { Link } from "react-router-dom";
import WhyWolvy from "./components/WhyWolvy";
import Feature from "./components/Feature";

const App = () => {
	return (
		<>
			<header className="min-h-screen relative z-10 flex flex-col">
				<div className="flex-1 flex justify-center items-center flex-col gap-7 md:w-full w-11/12 mx-auto after:top-0 after:left-0 after:absolute after:h-full after:w-full after:-z-10 after:bg-purpleSecondary/40">
					<p className="md:text-8xl text-6xl text-center font-bold ">
						Welcome to Wolvy!
					</p>
					<p className="md:max-w-[40%] max-w-full text-center">
						Your All-in-One Video Security and Streaming Platform At
						Wolvy, we redefine how you share and protect your
						digital content. From advanced multi-DRM protection to
						real-time analytics, our platform ensures your videos
						remain safe and deliver a seamless experience to your
						audience.
					</p>
					<Link
						to="/about"
						className="button-lg !border-none px-8 py-4 font-bold"
					>
						EXPLORE NOW
					</Link>
				</div>
			</header>
			<div className="py-20 bg-purpleSecondary">
				<p className="text-center  font-bold text-6xl underline">
					Why Wolvy?
				</p>
				<p className="text-center md:max-w-[40%] max-w-full my-3 mx-auto">
					Wolvy is not just a platform; it's your partner in
					safeguarding and optimizing video content. Built to serve
					creators, businesses, and enterprises, Wolvy ensures your
					content stays protected and your audience enjoys the best
					experience.
				</p>
				<div className="mt-10 px-10">
					<WhyWolvy
						titles={["Secure", "Streamlined", "Scalable"]}
						descriptions={[
							[
								"Advanced Multi-DRM Protection",
								"Shield your videos from unauthorized downloads, screen recording, and more with cutting-edge DRM technology.",
								"Customizable Encoding",
								"Prepare your videos for any device, any audience, and any resolution—up to 4K.",
							],
							[
								"Tailor your storage, bandwidth, and DRM options to fit your unique requirements.",
								"Upgrade anytime as your business scales.",
							],
							[
								"Global CDN for smooth, buffer-free streaming to your audience worldwide.",
								"Real-time analytics to optimize performance and reach.",
							],
						]}
					/>
				</div>
			</div>
			<div className="bg-gray flex flex-wrap items-center md:flex-row flex-col *:flex-1 md:p-10 p-5">
				<div className="md:mx-10 md:p-0 p-5">
					<p className="md:text-6xl text-3xl underline ">
						No Downloads Again
					</p>
					<p className="md:w-[50%] mt-5">
						Wolvy ensures your video content stays secure, blocking
						unauthorized downloads and screen recording with
						advanced DRM technology. Say goodbye to content theft
						and hello to peace of mind.
					</p>
				</div>
				<div>
					<img src="/images/no-downloads.webp" alt="No Downloads" />
				</div>
			</div>
			<div className="py-20 px-10 bg-purpleSecondary">
				<p className="text-4xl underline font-bold ">Choose you plan</p>
				<div className="flex md:flex-row flex-col justify-between *:flex-1 gap-5  mt-5">
					<div className="bg-gray p-5 flex flex-col gap-5">
						<p className="text-xl">Standard Plans</p>
						<p>Perfect for individulas starting out.</p>
						<Feature name="Affordable & Large Plans." />
						<Feature name="Wolvy Standard Security." />
						<Feature name="Multi DRM Support." check={false} />
						<Feature name="Customize Your Plans." />
						<p className="text-2xl">
							<span className="text-xs">Start From</span>{" "}
							$12/month
						</p>
						<Link
							to="/account-and-payments"
							className="text-center button-lg p-3 text-xl"
						>
							Subscribe
						</Link>
					</div>
					<div className="bg-gray p-5 flex flex-col gap-5">
						<p className="text-xl">Advanced Plans</p>
						<p>Perfect for Videos Shouldn't be Stolen.</p>
						<Feature name="Affordable & Large Plans." />
						<Feature name="Wolvy Advanced Security." />
						<Feature name="Multi DRM Support." />
						<Feature name="Customize Your Plans." />
						<p className="text-2xl">
							<span className="text-xs">Start From</span>{" "}
							$37/month
						</p>
						<Link
							to="/account-and-payments"
							className="text-center button-lg p-3 text-xl"
						>
							Subscribe
						</Link>
					</div>
					<div className="bg-gray p-5 flex flex-col gap-5">
						<p className="text-xl">High Usage?</p>
						<p>Customize Your Plan, Options and All your Needs!</p>
						<Feature name="Affordable & High Volume Plans." />
						<Feature name="High Security In Market." />
						<Feature name="Choose Your Options You Need." />
						<Feature name="Discounted Prices." />
						<p className="text-2xl">
							<span className="text-xs">Start From</span>{" "}
							$??/month
						</p>
						<Link
							to="/contact-us"
							className="text-center button-lg p-3 text-xl"
						>
							Contact Us
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default App;
