import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);
	const handleContact = (e) => {
		e.preventDefault();
		const name = e.target.name.value;
		const email = e.target.email.value;
		const message = e.target.message.value;
		if (!name || !email || !message) {
			setError("All fields are required.");
			return;
		}
		setLoading(true);
		setError(false);
		setDone(false);
		const formData = new FormData();
		formData.append("name", name);
		formData.append("email", email);
		formData.append("message", message);
		axios
			.post("/contact.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="bg-[rgb(22,22,22)] md:p-10 p-5 flex gap-10 flex-wrap *:flex-1 h-full">
			<div className="md:p-5 p-3 bg-gray">
				<p className=" text-3xl mb-5">Get in Touch</p>
				<form
					className="flex flex-col gap-5 [&_input]:w-full [&_input]:p-2 [&_input]:bg-[rgb(22,22,22)]"
					onSubmit={handleContact}
				>
					<div>
						<p>Name</p>
						<input
							type="text"
							required
							name="name"
							placeholder="Your Name"
						/>
					</div>
					<div>
						<p>Email</p>
						<input
							type="email"
							required
							name="email"
							placeholder="Your Email"
						/>
					</div>
					<div>
						<p>Message</p>
						<textarea
							rows={10}
							className="w-full bg-[rgb(22,22,22)] p-2"
							placeholder="Your Message"
							required
							name="message"
						/>
					</div>
					<input
						className="button p-3 text-black !bg-purplePrimary"
						type="submit"
						value={loading ? "LOADING..." : "SEND MESSAGE"}
						disabled={loading}
					/>
					{error && <p className="text-pink">{error}</p>}
					{done && (
						<p className="text-green">Message sent successfully!</p>
					)}
				</form>
			</div>
			<div className="p-5 bg-gray flex flex-col gap-5">
				<p className="text-xl">Contact Information</p>
				<p>
					Phone:{" "}
					<Link
						className="underline"
						target="_blank"
						to="https://wa.me/+201030088948"
					>
						+20103 008 8948
					</Link>
				</p>
				<p>
					Email:{" "}
					<Link
						to="mailto:contact@wolvy.net"
						className="underline"
						target="_blank"
					>
						contact@wolvy.net
					</Link>
				</p>
				<p className="text-xl">Our Office</p>
				<p>1st Floor in Kamarayet Roushdy Towers, Alexandria, Egypt</p>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27296.746602236453!2d29.976166408024017!3d31.21814372036383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c4e6fbdf109b%3A0x472b198beed506a3!2sRegus!5e0!3m2!1sar!2seg!4v1730717028886!5m2!1sar!2seg"
					allowfullscreen=""
					title="Map"
					loading="lazy"
					referrerpolicy="no-referrer-when-downgrade"
					style={{ height: "100%", width: "100%" }}
				></iframe>
			</div>
		</div>
	);
};

export default ContactUs;
