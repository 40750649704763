import React, { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import EmbedVideo from "../components/EmbedVideo";
import UploadSubtitle from "../components/UploadSubtitle";
import DeleteVideo from "../components/DeleteVideo";
import VideoDetails from "../components/File/VideoDetails";
import VideoPreview from "../components/File/VideoPreview";
import Chapters from "../components/File/Chapters";
import ByCountry from "../components/File/ByCountry";
import Highlights from "../components/File/Highlights";
import VideoAnalytics from "../components/File/VideoAnalytics";

const Video = () => {
	const [data, setData] = useState(useLoaderData()[0]);
	const navigate = useNavigate();

	useEffect(() => {
		if (data.status === "error") {
			navigate("/404");
		}
	}, []);
	const videoID = useLoaderData()[1];
	const [subtitle, setSubtitle] = useState(false);
	const [embedVideo, setEmbedVideo] = useState(false);
	const [deleteVideo, setDeleteVideo] = useState(false);
	if (data.status === "success") {
		return (
			<div className="h-full bg-purpleSecondary [&_*]:">
				<div className="md:p-7 p-3 border-b-[1px] border-b-gray">
					<p className="font-bold text-3xl break-words">
						{data.title}
					</p>
				</div>
				<div className="md:p-7 p-3">
					<p className="font-bold text-xl">Video Details</p>
					<div className="flex gap-10 md:flex-row flex-col *:flex-1 flex-wrap mt-5">
						<VideoDetails videoID={videoID} data={data} />
						<VideoPreview
							data={data}
							setEmbedVideo={setEmbedVideo}
							setSubtitle={setSubtitle}
							setDeleteVideo={setDeleteVideo}
						/>
					</div>
					<div className="flex gap-10 md:flex-row flex-col *:flex-1 flex-wrap mt-10">
						<Chapters
							data={data}
							setData={setData}
							videoID={videoID}
						/>
						<ByCountry data={data} />
					</div>
					<div className="flex gap-10 md:flex-row flex-col *:flex-1 flex-wrap mt-10">
						<Highlights data={data} videoID={videoID} />
						<VideoAnalytics data={data} />
					</div>
				</div>
				{embedVideo && (
					<EmbedVideo
						iframe_url={data.iframe_url}
						direct_play_url={data.direct_play_url}
						setEmbedVideo={setEmbedVideo}
						videoID={videoID}
					/>
				)}
				{subtitle && (
					<UploadSubtitle
						iframe_url={data.iframe_url}
						direct_play_url={data.direct_play_url}
						setSubtitle={setSubtitle}
						videoID={videoID}
					/>
				)}
				{deleteVideo && (
					<DeleteVideo
						videoID={[videoID]}
						setDeleteVideo={setDeleteVideo}
						videoName={[data.title]}
					/>
				)}
			</div>
		);
	} else {
		return <></>;
	}
};

export default Video;
