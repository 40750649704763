import React from "react";
import WhyWolvy from "../components/WhyWolvy";
import NeedHelp from "../components/NeedHelp";

const About = () => {
	return (
		<>
			<div className="md:h-[80vh] h-screen flex justify-center md:px-40 px-10 gap-5 z-20 *:z-20 about relative bg-center md:bg-contain bg-cover flex-col">
				<p className="text-8xl  uppercase">About Wolvy</p>
				{/* <p className="text-2xl">Passionate about digital innovation?</p> */}
			</div>
			<div className="bg-[rgb(22,22,22)] md:p-20 p-5">
				<div className="flex gap-20 md:flex-row flex-col *:flex *:flex-col *:justify-between *:gap-20 *:flex-1">
					<div>
						{/* <p className="text-5xl font-bold">Explore</p> */}
						<p className="text-xl font-bold">
							Wolvy is your trusted partner for secure and
							seamless video streaming. With advanced DRM
							protection, real-time insights, and customizable
							solutions, we empower creators and businesses to
							protect their content and deliver exceptional
							experiences to their audience.
						</p>
						<img
							src="/images/about-1.webp"
							className="md:h-[50rem] w-full md:object-cover object-contain"
							alt="About"
						/>
					</div>
					<div>
						<img
							src="/images/about-2.webp"
							className="md:h-[30rem] w-full md:object-cover object-contain"
							alt="About"
						/>
						<img
							src="/images/about-3.webp"
							className="md:h-[30rem] w-full md:object-cover object-contain"
							alt="About"
						/>
					</div>
				</div>
				<p className="mt-24 mb-16 font-bold text-5xl">Why Wolvy?</p>
				<WhyWolvy
					titles={[
						"Real-Time Analytics",
						" Flexible Plans and Scalability",
						"Branding Customization",
					]}
					descriptions={[
						[
							"Get actionable insights into your video performance, including viewership trends, engagement patterns, and location-based data, helping you optimize content and expand your reach",
						],
						[
							"Wolvy adapts to your needs, offering customizable plans for storage, bandwidth, and protection levels. As your audience grows, you can scale up seamlessly without disrupting your operations.",
						],
						[
							"Make Wolvy yours. Customize video players with your branding, ensuring your audience enjoys a cohesive and professional viewing experience that reflects your identity.",
						],
					]}
				/>
			</div>
			<NeedHelp />
		</>
	);
};

export default About;
