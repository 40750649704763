import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

const EmbedVideo = ({
	setEmbedVideo,
	iframe_url,
	direct_play_url,
	videoID,
}) => {
	const [copied, setCopied] = useState(false);
	const iframeRef = useRef();
	const wordpressRef = useRef();
	const directRef = useRef();
	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	// const handleIframeCode = () => {
	// 	const baseURL = "https://embed.wolvy.stream/" + videoID;
	// 	const data = iframe_url.split("embed.wolvy.stream/")[1].split("/");
	// 	const libID = btoa(btoa(data[0]));
	// 	const vid = btoa(btoa(data[1]));
	// 	const randomID = Math.random().toString(36).substring(2, 15);
	// 	const iframeID = "wf_" + randomID;

	// 	const part1 = `const wf=document.getElementById("${iframeID}");`;
	// 	const part2 = `const lib=atob(atob(wf.getAttribute("l")));`;
	// 	const part3 = `const v=atob(atob(wf.getAttribute("v")));`;
	// 	const part4 = `wf.src=["https://iframe.mediadelivery.net/embed/",lib,"/",v].join("")+"?autoplay=false&responsive=true";`;

	// 	const fullScript = part1 + part2 + part3 + part4;
	// 	const encodedScript = btoa(fullScript);

	// 	return `<iframe src="${baseURL}" id="${iframeID}" l="${libID}" v="${vid}" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true" allow="encrypted-media"></iframe><script>(function(){eval(atob('${encodedScript}'))})();</script>`;
	// };

	const handleWordpressShortcode = () => {
		const data = iframe_url.split("embed.wolvy.stream/")[1].split("/");
		const libID = btoa(btoa(data[0]));
		const vid = btoa(btoa(data[1]));
		return `[wolvy lid="${libID}" vid="${vid}" new_structure="true"]`;
	};

	return (
		<div className="fixed top-0 left-0 h-screen w-screen z-50 flex justify-center items-center bg-white/50">
			<div className="h-96 w-full md:w-1/3 bg-[#d4bdf1] shadow-[15px_15px_0_0_#3f1f61] border-2 border-purpleSecondary flex flex-col">
				<div className="flex bg-purplePrimary justify-end p-5 border-b-2 border-purpleSecondary">
					<img
						src="/images/o-x.webp"
						className="h-6 cursor-pointer select-none"
						alt="x-o"
						onClick={() => setEmbedVideo(false)}
					/>
				</div>
				<div className="h-full flex justify-center items-center flex-col">
					<p className="w-10/12 text-black">Iframe Code</p>
					<div className="flex gap-5 w-10/12 mb-5">
						<input
							type="text"
							className="text-black p-2 h-10 w-full border-[1px] border-purpleSecondary bg-transparent"
							// value={handleIframeCode()}
							value={`<div style="position:relative;padding-top:56.25%"><iframe src="${iframe_url}" style="border:0;position:absolute;top:0;height:100%;width:100%" allow="accelerometer;gyroscope;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe></div>`}
							readOnly
							ref={iframeRef}
						/>
						<div
							className="aspect-square h-full border-[1px] border-purpleSecondary button-lg flex justify-center items-center !bg-yellow"
							onClick={() => {
								navigator.clipboard.writeText(
									iframeRef.current?.value
								);
								setCopied(true);
							}}
						>
							<FontAwesomeIcon
								icon={faCopy}
								className="*:text-black"
							/>
						</div>
					</div>
					<p className="w-10/12 text-black">Wordpress Shortcode</p>
					<div className="flex gap-5 w-10/12 mb-5">
						<input
							type="text"
							className="text-black p-2 h-10 w-full border-[1px] border-purpleSecondary bg-transparent"
							value={handleWordpressShortcode()}
							readOnly
							ref={wordpressRef}
						/>
						<div
							className="aspect-square h-full border-[1px] border-purpleSecondary button-lg flex justify-center items-center !bg-yellow"
							onClick={() => {
								navigator.clipboard.writeText(
									wordpressRef.current?.value
								);
								setCopied(true);
							}}
						>
							<FontAwesomeIcon
								icon={faCopy}
								className="*:text-black"
							/>
						</div>
					</div>
					<p className="w-10/12 text-black">Direct Play URL</p>
					<div className="flex gap-5 w-10/12 mb-5">
						<input
							type="text"
							className="text-black p-2 h-10 w-full border-[1px] border-purpleSecondary bg-transparent"
							value={direct_play_url
								.split("play.wolvy.stream/")
								.join("play.wolvy.stream/#/")}
							readOnly
							ref={directRef}
						/>
						<div
							className="aspect-square h-full border-[1px] border-purpleSecondary button-lg flex justify-center items-center !bg-yellow"
							onClick={() => {
								navigator.clipboard.writeText(
									directRef.current?.value
								);
								setCopied(true);
							}}
						>
							<FontAwesomeIcon
								icon={faCopy}
								className="*:text-black"
							/>
						</div>
					</div>
					<p className="text-black text-sm w-10/12 mx-auto">
						* Play link won't work if you added Allowed Domain in
						your options page, use embed code on your allwed domain
						instead.
					</p>
					{copied && (
						<p className="text-center text-green">
							Copied to the clipboard!
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmbedVideo;
