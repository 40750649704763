import React from "react";
import Plans from "../components/Plans";
import Feature from "../components/Feature";
import { Link } from "react-router-dom";
import FAQ from "../components/FAQ";

const Pricing = () => {
	const standard = [
		"Download Protection: Prevent unauthorized downloading of your videos.",
		"ClearKey AES-128: Basic encryption to safeguard your content.",
		"Browser Support: Seamless compatibility with major web browsers.",
		"Integrated Player: Built-in video player for effortless streaming.",
		"Custom Player Support: Personalize your video experience with custom player options.",
	];
	const advanced = [
		"Download Protection: Prevent unauthorized downloading of your videos.",
		"Secure Key Management: Keep your encryption keys safe and secure.",
		"Browser Support: Seamless compatibility with major web browsers.",
		"Integrated Player: Built-in video player for effortless streaming.",
		"Widevine & FairPlay Support: Comprehensive DRM protection for all devices.",
		"Screenshot/Screen Record Protection: Industry-leading features to block screen grabs.",
		"Custom Player Support: Personalize your video experience with custom player options.",
	];
	return (
		<div className="bg-[rgb(22,22,22)] md:py-32 py-10 h-full">
			<div className="mx-auto md:w-3/4">
				<p className=" text-4xl text-center font-bold">Pricing</p>
				<p className=" text-xl max-w-1/4 text-center">
					Choose the plan that works best for your business
				</p>
				<Plans planWord={true} contactUsSection={true} />
				<div className="md:mt-20 mt-5 md:p-0 px-5 *:font-bold">
					<p className="text-4xl">Compare Features</p>
					<div className="mt-10 flex *:flex-1 md:flex-row flex-col md:gap-0 gap-10">
						<div>
							<p className="text-3xl">Standard</p>
							<div className="h-0.5 bg-gray w-full my-10"></div>
							<div className="flex flex-col gap-10">
								{standard.map((f, i) => (
									<Feature key={i} name={f} />
								))}
							</div>
						</div>
						<div>
							<p className="text-3xl">Advanced</p>
							<div className="h-0.5 bg-gray w-full my-10"></div>
							<div className="flex flex-col gap-10">
								{advanced.map((f, i) => (
									<Feature key={i} name={f} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-gray mt-10">
				<div className="flex *:flex-1 py-10 flex-wrap mx-auto md:w-3/4 w-11/12 md:flex-row flex-col">
					<div className="flex flex-col justify-evenly *:font-bold *: md:p-0 p-5 md:gap-0 gap-10">
						<p className="md:text-5xl text-3xl">Choose a Plan</p>
						<p className="text-xl">
							Select a plan that fits your business needs.
						</p>
						<div>
							<Link
								to="/login"
								className="button-lg text-lg py-3 px-14 text-black"
							>
								GET STARTED
							</Link>
						</div>
					</div>
					<div className="flex justify-center">
						<img
							src="/images/choose.webp"
							className="h-96"
							alt="Choose"
						/>
					</div>
				</div>
			</div>
			<div className="pt-10">
				<div className="mx-auto md:w-3/4 w-11/12 md:flex-row flex-col flex *:flex-1 flex-wrap [&_p]:">
					<FAQ />
				</div>
			</div>
		</div>
	);
};

export default Pricing;
