import React from "react";
import { moveToFolder } from "../../utils/moveToFolder";
import { useNavigate } from "react-router-dom";

const ContextVideoMenu = ({
	menuPosition,
	selections,
	setSelections,
	// setMoving,
	fileActive,
	setDeleteVideo,
	setVideoDeleted,
	flipped,
}) => {
	const navigate = useNavigate();
	const handleMove = async () => {
		await moveToFolder(
			selections.filter(
				(obj1, i, arr) =>
					arr.findIndex((obj2) => obj2.id === obj1.id) === i
			),
			0
		);
		navigate("/folders");
	};
	const handleDelete = () => {
		setVideoDeleted({
			videoID: selections.map((s) => s.id),
			videoName: selections.map((s) => s.title),
		});
		setDeleteVideo(true);
	};
	return (
		<div
			className={`${
				flipped && "-translate-x-full"
			} absolute min-w-72 flex flex-col cursor-pointer z-50 border-[1px] border-black`}
			style={{
				top: `${menuPosition.y}px`,
				left: `${menuPosition.x}px`,
			}}
		>
			<div
				className={`px-5 py-3 border-b-[1px] border-b-black ${
					selections.length || fileActive
						? "bg-purplePrimary"
						: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
				}`}
				onClick={handleMove}
			>
				Move out of the folder
			</div>
			<div
				className={`px-5 py-3 border-b-[1px] border-b-black ${
					selections.length || fileActive
						? "bg-purplePrimary"
						: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
				}`}
				onClick={handleDelete}
			>
				Delete
			</div>
			<div
				className={`px-5 py-3 border-b-[1px] border-b-black ${
					selections.length
						? "bg-purplePrimary"
						: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
				}`}
				onClick={() => selections.length && setSelections([])}
			>
				Remove Selections
			</div>
		</div>
	);
};

export default ContextVideoMenu;
