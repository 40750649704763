import React from "react";
import { useLoaderData } from "react-router-dom";
import File from "../components/File";

const VideosList = () => {
	const data = useLoaderData();
	const videos = data[0];
	return (
		<div className="h-full bg-purpleSecondary [&_*]:">
			<div className="md:p-7 p-3 border-b-[1px] border-b-gray">
				<p className="font-bold text-3xl">Videos List</p>
			</div>
			<div className="p-7 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-y-10">
				{videos.videos?.map((v, i) => (
					<File key={i} {...v} />
				))}
			</div>
		</div>
	);
};

export default VideosList;
