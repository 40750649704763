import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../utils/getUser";
import { getAPIKey } from "../../utils/getAPIKey";

const VideoDetails = ({ videoID, data }) => {
	const navigate = useNavigate();
	const thumbnailRef = useRef(null);

	const [thumbnail, setThumbnail] = useState(null);
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [error, setError] = useState(false);

	const handleVideoDetails = (e) => {
		e.preventDefault();
		setLoading(true);
		setDone(false);
		setError(false);
		const title = e.target.title.value || "";
		const description = e.target.description.value || "";
		const formData = new FormData();
		const user = getUser();
		const apiKey = getAPIKey();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("video_id", videoID);
		formData.append("title", title);
		formData.append("description", description);
		formData.append("isChangingThumbinalFlag", thumbnail ? true : false);
		formData.append("image", thumbnail);
		axios
			.post("/edit_video_basic.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
					navigate(0);
				} else if (
					response.data.message ===
					"Video ID, User ID, Title, and Description are required."
				) {
					setError("Title, and Description are required.");
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="!bg-gray p-5 button-lg-no-active">
			<form className="flex flex-col gap-5" onSubmit={handleVideoDetails}>
				<p className="font-bold">Video Name</p>
				<input
					type="text"
					name="title"
					className="bg-grayLight p-2"
					placeholder="Enter Video Name"
					defaultValue={data.title}
				/>
				<p className="font-bold">Description</p>
				<textarea
					type="text"
					name="description"
					className="bg-grayLight p-2"
					placeholder="Enter Description"
					rows={5}
					defaultValue={data.description}
				/>
				<p className="font-bold">
					You can upload a new thumbinal for your video Supported
					files: JPG, JPEG, PNG
				</p>
				<div className="mx-auto relative">
					<div
						className="button-lg after:h-full after:w-full after:z-0 after:bg-[rgba(255,255,255,0.3)] after:absolute after:top-0 after:left-0"
						onClick={() => {
							thumbnailRef?.current.click();
						}}
					>
						<input
							type="file"
							ref={thumbnailRef}
							hidden
							onChange={(e) => setThumbnail(e.target.files[0])}
						/>
						<img
							src={
								thumbnail
									? URL.createObjectURL(thumbnail)
									: data.poster
							}
							alt={data.title}
							className="h-40 w-72 object-cover"
						/>
						<div className="center-absolute">
							<img
								src="/images/upload.svg"
								alt="Uplaod Video"
								className="h-14"
							/>
						</div>
					</div>
				</div>
				<input
					type="submit"
					className="button-lg p-4 font-bold"
					value={loading ? "LOADING..." : "UPDATE"}
				/>
				{error && <p className="text-center mt-2 text-pink">{error}</p>}
				{done && (
					<p className="text-center mt-2 text-green">
						Video Details updated successfully!
					</p>
				)}
			</form>
		</div>
	);
};

export default VideoDetails;
