import React from "react";
import { moveToFolder } from "../../utils/moveToFolder";
import { useNavigate } from "react-router-dom";

const ContextMenu = ({
	menuPosition,
	moving,
	folderActive,
	flipped,
	setAlert,
	setAlertAction,
}) => {
	const navigate = useNavigate();
	const handleMove = async () => {
		if (!moving.length || !folderActive) return;
		await moveToFolder(moving, folderActive.id);
		navigate(`/folders/${folderActive.id}`);
	};

	return (
		<>
			<div
				className={`${
					flipped && "-translate-x-full"
				} absolute min-w-72 flex flex-col cursor-pointer z-50 border-[1px] border-black`}
				style={{
					top: `${menuPosition.y}px`,
					left: `${menuPosition.x}px`,
				}}
			>
				<div
					className={`px-5 py-3 border-b-[1px] border-b-black ${
						moving.length && folderActive
							? "bg-purplePrimary"
							: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
					}`}
					onClick={handleMove}
				>
					Move To
				</div>
				<div
					className={`px-5 py-3 border-b-[1px] border-b-black ${
						folderActive
							? "bg-purplePrimary"
							: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
					}`}
					onClick={() => {
						setAlert(true);
						setAlertAction("delete");
					}}
				>
					Delete Folder
				</div>
			</div>
		</>
	);
};

export default ContextMenu;
