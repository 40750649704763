import React, { useEffect, useRef, useState } from "react";
import { getUser } from "../../utils/getUser";
import axios from "axios";
import { getAPIKey } from "../../utils/getAPIKey";
import { addPlayerColor, getPlayerColor } from "../../utils/playerColor";

const PlayerColor = ({ player_color }) => {
	const [playerColor, setPlayerColor] = useState(player_color);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	const playerColorInput = useRef(null);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	useEffect(() => {
		if (playerColor) {
			playerColorInput.current.value = playerColor;
		}
	}, [playerColor]);

	function isValidHexColor(hex) {
		return /^#[0-9A-F]{6}$/i.test(hex);
	}

	const handlePlayerColor = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(false);
		setDone(false);
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		if (isValidHexColor(playerColor)) {
			formData.append("hex_color", playerColor || "#000000");
		} else {
			formData.append("hex_color", "#000000");
		}
		addPlayerColor(playerColor || "#000000");
		axios
			.post("/edit_player_color.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const playerColors = getPlayerColor();

	return (
		<div className="flex flex-col mt-5">
			<p className="font-bold text-xl mb-5">Player Color</p>
			<div className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active">
				<form
					className="flex-1 flex flex-col justify-between gap-5 *:font-bold"
					onSubmit={handlePlayerColor}
				>
					<div className="flex justify-between items-center gap-5">
						<div className="grid grid-cols-5 gap-5">
							<div
								data-color="#7e3dd9"
								onClick={(e) =>
									setPlayerColor(
										e.target.getAttribute("data-color")
									)
								}
								className="button-lg h-7 w-7 !bg-purplePrimary"
							></div>
							<div
								data-color="#f7ee7f"
								onClick={(e) =>
									setPlayerColor(
										e.target.getAttribute("data-color")
									)
								}
								className="button-lg h-7 w-7 !bg-yellow"
							></div>
							<div
								data-color="#201226"
								onClick={(e) =>
									setPlayerColor(
										e.target.getAttribute("data-color")
									)
								}
								className="button-lg h-7 w-7 !bg-purpleSecondary"
							></div>
							<div
								data-color="#e83f6f"
								onClick={(e) =>
									setPlayerColor(
										e.target.getAttribute("data-color")
									)
								}
								className="button-lg h-7 w-7 !bg-pink"
							></div>
							<div
								data-color="#05b788"
								onClick={(e) =>
									setPlayerColor(
										e.target.getAttribute("data-color")
									)
								}
								className="button-lg h-7 w-7 !bg-green"
							></div>
							{[
								...playerColors,
								...Array(5 - playerColors.length).fill(
									"#FFFFFF"
								),
							].map((color, index) => (
								<div
									key={index}
									data-color={color}
									onClick={(e) =>
										setPlayerColor(
											e.target.getAttribute("data-color")
										)
									}
									className="button-lg h-7 w-7"
									style={{ backgroundColor: color }}
								></div>
							))}
						</div>
						<div>
							<p className=" mb-3">Pick a color</p>
							<div className="flex button-lg *:flex-1">
								<input
									type="color"
									value={playerColor || player_color}
									onChange={(e) =>
										setPlayerColor(e.target.value)
									}
								/>
								<input
									className="px-5 text-sm w-28 bg-purpleSecondary"
									defaultValue={playerColor}
									ref={playerColorInput}
									min={7}
									max={7}
									onChange={(e) => {
										if (!e.target.value.includes("#")) {
											e.target.value =
												"#" +
												e.target.value.slice(0, 6);
										}
										setPlayerColor(e.target.value);
									}}
								/>
							</div>
						</div>
					</div>
					<input
						type="submit"
						className="button-lg p-3"
						value={loading ? "LOADING..." : "UPDATE"}
					/>
				</form>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">Player Color has been updated!</p>
				)}
			</div>
		</div>
	);
};

export default PlayerColor;
