import axios from "axios";
import React, { useState } from "react";
import { getUser } from "../../utils/getUser";
import Pagination from "../Pagination";
import { formatBytes } from "../../utils/formatBytes";
import { getAPIKey } from "../../utils/getAPIKey";

const History = ({ data }) => {
	const [history, setHistory] = useState(data[0].data);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const updateHistory = (page) => {
		setLoading(true);
		setError(false);
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("page", page);
		formData.append("limit", 8);
		axios
			.post("/get_payments.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setHistory(response.data);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="md:p-7 p-3">
			<div className="flex justify-between items-center">
				<p className=" font-bold text-xl">History</p>
			</div>
			<div className="mt-5 pb-5 bg-gray overflow-x-auto relative">
				{loading && (
					<div className="absolute z-50 top-0 left-0 h-full w-full bg-white/50 flex justify-center items-center">
						<p className="text-black">Loading ....</p>
					</div>
				)}
				<table className="w-full">
					<thead className="text-2xl [&_td]:p-5">
						<tr className="border-b-8 border-b-[rgba(0,0,0,0.1)]">
							<td>Date</td>
							<td>Storage</td>
							<td>Bandwidth</td>
							<td>Total</td>
							<td>Gateway</td>
							<td>Status</td>
						</tr>
					</thead>
					<tbody className="[&_td]:p-5">
						{history.data.length ? (
							""
						) : (
							<tr className="text-center p-10">
								<td colSpan={6}>No Payment History yet</td>
							</tr>
						)}
						{history.data.map((h, i) => (
							<tr key={i}>
								<td>{h.date.split(" ")[0]}</td>
								<td>{formatBytes(h.storage)}</td>
								<td>{formatBytes(h.bandwidth)}</td>
								<td>{h.amount}$</td>
								<td>{h.payment_gateway}</td>
								<td>{h.status}</td>
							</tr>
						))}
					</tbody>
				</table>
				{history.pagination.total_pages > 1 && (
					<Pagination
						pages={history.pagination.total_pages}
						current={history.pagination.current_page}
						update={updateHistory}
					/>
				)}
				{error && <p className="text-pink">{error}</p>}
			</div>
		</div>
	);
};

export default History;
