import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { encode } from "../utils/crypto";

const Login = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState(false);
	const [password, setPassword] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const redirect = searchParams.get("redirect");
	const handleLogin = (e) => {
		setLoading(true);
		setEmail(false);
		setPassword(false);
		setError(false);
		e.preventDefault();
		const email = e.target.email_or_username.value;
		const password = e.target.password.value;
		if (!email) setEmail("Email or Username is required.");
		if (!password) setPassword("password is required.");
		if (!email || !password) {
			setLoading(false);
			return;
		}
		const formData = new FormData();
		let modifiedEmail = email;
		if (email.split("@").lenght >= 2) {
			modifiedEmail =
				email.split("@")[0].split(".").join("") +
				"@" +
				email.split("@")[1];
		}
		formData.append("username_or_email", modifiedEmail);
		formData.append("password", password);
		axios
			.post("/login.php", formData)
			.then((result) => {
				if (result.data.status === "success") {
					const userStatus = result.data.user.status;
					if (userStatus === "active") {
						setSuccess(true);
						const encoded = encode(result.data.user.id);
						sessionStorage.setItem("session", encoded.part1);
						sessionStorage.setItem("vid", encoded.part2);
						sessionStorage.setItem(
							"_etg",
							result.data.user.api_key
						);
						setTimeout(() => {
							if (redirect) {
								navigate(redirect);
							} else {
								navigate("/dashboard");
							}
						}, 2000);
					} else if (userStatus === "inactive") {
						setError(
							"Your account is inactive, please contact the support!"
						);
					} else if (userStatus === "suspend") {
						setError(
							"Your account is suspended, please contact the support!"
						);
					}
				} else if (result.data.status === "error") {
					setError("An error occurred, Please try again!");
				}
				setLoading(false);
			})
			.catch((err) => {
				setError(err.message);
				setLoading(false);
			});
	};
	return (
		<div className="flex justify-center items-center bg-purpleSecondary min-h-screen w-full">
			<div className="md:p-10 p-5 bg-[rgb(61,61,61)] flex flex-col gap-5 border-2 border-black shadow-[1px_1px_0_0_#000]">
				<p className="md:text-5xl text-3xl">Welcome Back</p>
				<form
					onSubmit={handleLogin}
					className="flex flex-col md:max-w-[500px] gap-5 min-w-80 [&>div]:flex [&>div]:flex-col"
				>
					<div>
						<label
							className="font-semibold"
							htmlFor="email_or_username"
						>
							Email or Username
						</label>
						<input
							type="text"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="email_or_username"
							name="email_or_username"
							placeholder="Enter your Email or Username"
							required
						/>
						{email && (
							<p className="text-pink text-sm font-bold">
								{email}
							</p>
						)}
					</div>
					<div>
						<label
							className="font-semibold"
							htmlFor="password"
							required
						>
							Password
						</label>
						<input
							type="password"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="password"
							name="password"
							placeholder="Enter your password"
						/>
						{password && (
							<p className="text-pink text-sm font-bold">
								{password}
							</p>
						)}
					</div>
					{error && (
						<p className="text-pink text-sm font-bold">{error}</p>
					)}
					{success && (
						<p className="text-green text-center text-sm font-bold">
							Logged in successfully! You will be redirected to
							Dashboard.
						</p>
					)}
					<input
						type="submit"
						className="p-2 button"
						disabled={loading}
						value={loading ? "Loading..." : "LOGIN"}
					/>
					<p className="text-center ">
						<span>Don't have an account? </span>
						<Link
							to="/register"
							className="text-purplePrimary inline"
						>
							Create one
						</Link>
					</p>
					<p className="text-center">
						<Link
							to="/forget"
							className="text-purplePrimary text-center"
						>
							Forget Password?
						</Link>
					</p>
				</form>
			</div>
		</div>
	);
};

export default Login;
