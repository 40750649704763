export const getPlayerColor = () => {
	return JSON.parse(localStorage.getItem("playerColor")) || [];
};

export const addPlayerColor = (code) => {
	const playerColor = getPlayerColor();
	playerColor.length === 5 && playerColor.shift();
	playerColor.push(code);
	localStorage.setItem("playerColor", JSON.stringify(playerColor));
};
