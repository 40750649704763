import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PlanRange from "./PlanRange";
import { formatBytes } from "../utils/formatBytes";

const Plan = ({
	id,
	price,
	period = true,
	name,
	storage,
	bandwidth,
	customize,
	overusage = false,
	setCurrentPlan = false,
	plan,
	min,
	max,
	min2,
	max2,
	step1 = 1,
	step2 = 1,
	setPrice = null,
	planType = null,
	userPlan,
}) => {
	const [customizedStorage, setCustomizedStorage] = useState([min]);
	const [customizedBandwidth, setCustomizedBandwidth] = useState([min2]);
	const [customizedPrice, setCustomizedPrice] = useState(10);

	useEffect(() => {
		if (setPrice) {
			setCustomizedPrice(
				setPrice(customizedStorage[0], customizedBandwidth[0])
			);
		}
	}, [customizedStorage, customizedBandwidth, planType]);

	const handleChangePlan = () => {
		setCurrentPlan({
			id,
			type: customize ? "custom" : overusage ? "overusage" : "fixed",
			name,
			storage: customize ? customizedStorage : storage,
			bandwidth: customize ? customizedBandwidth : bandwidth,
			price: customize ? Math.ceil(customizedPrice / 12) * 12 : price,
			plan,
		});
	};

	const isActive =
		(userPlan?.plan_type === "fixed" && userPlan?.plan_id === id) ||
		userPlan?.plan_type === id;

	return (
		<div
			className={`md:p-10 p-5 md:flex-row flex-col gap-3 text-center shadow-[4px_4px_0_0_#000] bg-purplePrimary [&_p]:text-purpleSecondary flex justify-between md:items-center *:flex-1
		${
			((userPlan?.plan_type === "fixed" && userPlan?.plan_id === id) ||
				userPlan?.plan_type === id) &&
			"!bg-yellow"
		}`}
		>
			<div className="flex flex-col">
				<p className="text-2xl font-bold text-start">
					{customize
						? !period
							? Math.ceil(customizedPrice)
							: Math.ceil(customizedPrice / 12)
						: Math.ceil(price / 12)}
					${period ? "/Month" : ""}
				</p>
				{period ? (
					<p className="text-xs text-start">Billed Annually</p>
				) : (
					""
				)}
			</div>
			<p className="text-lg font-bold text-start">{name}</p>
			{customize ? (
				<>
					<div className="flex flex-col gap-3 items-center *:text-purpleSecondary">
						<p className="text-lg font-bold">
							{formatBytes(customizedStorage)} Storage
						</p>
						<PlanRange
							min={min}
							max={max}
							number={customizedStorage}
							setNumber={setCustomizedStorage}
							isActive={isActive}
							step={step1}
						/>
					</div>
					<div className="flex flex-col gap-3 items-center *:text-purpleSecondary">
						<p className="text-lg font-bold">
							{formatBytes(customizedBandwidth)} Bandwidth
						</p>
						<PlanRange
							min={min2}
							max={max2}
							number={customizedBandwidth}
							setNumber={setCustomizedBandwidth}
							isActive={isActive}
							step={step2}
						/>
					</div>
				</>
			) : (
				<>
					<p className="text-lg text-left font-bold">
						{formatBytes(storage)} Storage
					</p>
					<p className="text-lg text-left font-bold">
						{formatBytes(bandwidth)} Bandwidth
					</p>
				</>
			)}
			<div className="flex justify-end">
				{setCurrentPlan ? (
					<div
						className={`button w-48 py-3 !text-white ${
							isActive && userPlan?.plan_type !== "custom"
								? "!bg-purplePrimaryDisabled !cursor-not-allowed"
								: "!bg-purpleSecondary"
						}`}
						onClick={
							isActive && userPlan?.plan_type !== "custom"
								? () => {}
								: handleChangePlan
						}
					>
						{isActive ? "CURRENT PLAN" : "BUY NOW"}
					</div>
				) : (
					<Link
						to="/account-and-payments"
						className="button w-48 py-3 !text-white !bg-purpleSecondary"
					>
						BUY NOW
					</Link>
				)}
			</div>
		</div>
	);
};

export default Plan;
