import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState(false);
	const [name, setName] = useState(false);
	const [email, setEmail] = useState(false);
	const [phone, setPhone] = useState(false);
	const [password, setPassword] = useState(false);
	const [checkPassword, setCheckPassword] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const validatePassword = (password, setPassword) => {
		setPassword("");
		if (password === "") {
			setPassword("");
			return 0;
		}
		if (password.length < 8) {
			setPassword("Password must be at least 8 characters long.");
			return 0;
		}
		if (!/[a-z]/.test(password)) {
			setPassword("Password must contain at least one lowercase letter.");
			return 0;
		}
		if (!/[A-Z]/.test(password)) {
			setPassword("Password must contain at least one uppercase letter.");
			return 0;
		}
		if (!/[\W_]/.test(password)) {
			setPassword("Password must contain at least one symbol.");
			return 0;
		}
		return 1;
	};

	const handleRegisteration = (e) => {
		e.preventDefault();
		setLoading(true);
		setUsername(false);
		setName(false);
		setEmail(false);
		setPhone(false);
		setPassword(false);
		setCheckPassword(false);
		setError(false);
		const username = e.target.username.value;
		const name = e.target.name.value;
		const email = e.target.email.value;
		const phone = e.target.phone.value;
		const password = e.target.password.value;
		const checkPassword = e.target.confirm_password.value;
		if (!username) setUsername("Username is required.");
		if (!name) setName("Name is required.");
		if (!email) setEmail("Email is required.");
		if (!email) setPhone("Email is required.");
		if (!password) setPassword("password is required.");
		if (!checkPassword) setCheckPassword("Confirm Password is required.");
		if (checkPassword !== password)
			setCheckPassword("Confirm Password and Password must be the same.");
		const validated = validatePassword(password, setPassword);
		if (
			!username ||
			!name ||
			!email ||
			!password ||
			!checkPassword ||
			checkPassword !== password ||
			!validated
		) {
			setLoading(false);
			return;
		}
		const modifiedEmail =
			email.split("@")[0].split(".").join("") + "@" + email.split("@")[1];
		const formData = new FormData();
		formData.append("name", name);
		formData.append("username", username);
		formData.append("email", modifiedEmail);
		formData.append("password", password);
		formData.append("phone", phone);

		axios
			.post("/registeration.php", formData)
			.then((result) => {
				if (result.data.status === "success") {
					setSuccess(true);
					setTimeout(() => {
						navigate("/login");
					}, 2000);
				} else if (result.data.status === "error") {
					setError(result.data.message);
				}
				setLoading(false);
			})
			.catch((err) => {
				setError(err.message);
				setLoading(false);
			});
	};
	return (
		<div className="flex justify-center items-center bg-purpleSecondary min-h-screen w-full">
			<div className="md:p-10 p-5 bg-[rgb(61,61,61)] flex flex-col gap-5 md:max-w-[500px] md:w-auto w-full border-2 border-black shadow-[1px_1px_0_0_#000]">
				<p className="md:text-5xl text-3xl">Create Account</p>
				<form
					onSubmit={handleRegisteration}
					className="flex flex-col gap-5 [&>div]:flex [&>div]:gap-2 [&>div]:flex-col md:min-w-96"
				>
					<div>
						<label className="font-semibold" htmlFor="username">
							Username
							<span className="ms-2 text-pink">*</span>
						</label>
						<input
							type="text"
							name="username"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="username"
							placeholder="Enter your username"
						/>
						{username && (
							<p className="text-pink text-sm font-bold">
								{username}
							</p>
						)}
					</div>
					<div>
						<label className="font-semibold" htmlFor="name">
							Full Name
							<span className="ms-2 text-pink">*</span>
						</label>
						<input
							type="text"
							name="name"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="name"
							placeholder="Enter your Name"
						/>
						{name && (
							<p className="text-pink text-sm font-bold">
								{name}
							</p>
						)}
					</div>
					<div>
						<label className="font-semibold" htmlFor="email">
							Email
							<span className="ms-2 text-pink">*</span>
						</label>
						<input
							type="email"
							name="email"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="email"
							placeholder="Enter your email"
						/>
						{email && (
							<p className="text-pink text-sm font-bold">
								{email}
							</p>
						)}
					</div>
					<div>
						<label className="font-semibold" htmlFor="phone">
							Phone Number
							<span className="ms-2 text-pink">*</span>
						</label>
						<input
							type="tel"
							name="phone"
							required
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="phone"
							placeholder="Enter your phone number"
						/>
						{phone && (
							<p className="text-pink text-sm font-bold">
								{phone}
							</p>
						)}
					</div>
					<div>
						<label className="font-semibold" htmlFor="password">
							Password
							<span className="ms-2 text-pink">*</span>
						</label>
						<input
							type="password"
							name="password"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="password"
							placeholder="Enter your password"
							onInput={(e) =>
								validatePassword(e.target.value, setPassword)
							}
						/>
						{password && (
							<p className="text-pink text-sm font-bold">
								{password}
							</p>
						)}
					</div>
					<div>
						<label
							className="font-semibold"
							htmlFor="confirm_password"
						>
							Confirm password
							<span className="ms-2 text-pink">*</span>
						</label>
						<input
							type="password"
							name="confirm_password"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)]  text-sm"
							id="confirm_password"
							placeholder="Confirm your password"
						/>
						{checkPassword && (
							<p className="text-pink text-sm font-bold">
								{checkPassword}
							</p>
						)}
					</div>
					{error && (
						<p className="text-pink text-sm font-bold">{error}</p>
					)}
					{success && (
						<p className="text-green text-center text-sm font-bold">
							User created successfully! You will be redirected to
							Log In page.
						</p>
					)}
					<input
						type="submit"
						className="p-2 button"
						disabled={loading}
						value={loading ? "Loading..." : "REGISTER"}
					/>
					<p className="flex gap-2 justify-center ">
						Already have an account?
						<Link to="/login" className="text-purplePrimary ">
							Log in
						</Link>
					</p>
				</form>
			</div>
		</div>
	);
};

export default Register;
